button.toggle-sidenav {
  &:hover,
  &:active,
  &:focus {
    border: none;
    background-color: unset;
  }
}

.sidenav-component-container {
  display: none;
}
.mat-drawer-opened {
  overflow: auto !important;
  box-shadow: none !important;
  header.sidenav-header {
    padding-right: 20px;
    border-bottom: 1px solid #dddddd;
    width: unset;
    .toggle-sidenav {
      transform: rotate(180deg);
    }
  }
  .sidenav-component-container {
    display: block;
    height: calc(100% - 100px);
    overflow-y: auto;
  }
}

.sidenav-container {
  width: 450px;
  overflow: visible !important;
  .mat-drawer-inner-container {
    overflow: visible;
  }
}

.mat-drawer {
  visibility: visible !important;
}
.mat-sidenav-content {
  overflow: unset !important;
  background: white;
}

header.sidenav-header {
  padding: 23px 5px 22px 25px;
  width: 500px;
  .left-column {
    width: 300px;
    .page-title {
      line-height: 40px;
    }
  }
  .right-column {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .mat-drawer-opened {
    width: 100%;
  }
  header.sidenav-header {
    padding: 6px 6px 6px 25px;
  }
  .mat-sidenav-content {
    margin-left: 0;
  }
}
