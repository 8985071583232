header {
  max-width: 1024px;

  .left-column {
    float: left;
    width: 50%;
  }

  .right-column {
    float: right;
    padding: 10px 0 9px 16px;
    @include x-icon-button;
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.sticky-header {
  width: calc(100% + 100px);
  max-width: unset;
  background-color: #eeeeee;
  z-index: 10;
  position: sticky;
  top: 60px;
  padding: 15px 25px 15px 50px;
  margin: -25px 0 -25px -50px;
  box-shadow: 0 1px 1px #dddddd;
  &::after {
    content: '';
    clear: both;
    display: table;
  }
}

.title-heading {
  padding-bottom: 20px;
}

.icon-header {
  .icon-container {
    background-color: #461e7d;
    height: 55px;
    width: 55px;
    margin: 0 15px 0 5px;
    border-radius: 5px;
    float: left;

    .mat-icon {
      color: white;
      font-size: 41px;
      text-align: center;
      line-height: 55px;
      height: 55px;
      width: 55px;
    }

    .mat-spinner {
      margin: 12.5px;

      circle {
        stroke: white;
        -webkit-font-smoothing: antialiased;
      }
    }
  }

  h1 {
    margin-bottom: 3px;
    padding-top: 7px;
  }

  p.subtitle {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  header .left-column,
  header .right-column {
    width: unset;
  }

  .sticky-header {
    padding: 10px 15px 10px 50px;
    .left-column {
      width: calc(100% - 130px);
      .icon-header {
        h1 {
          margin: 0;
          padding: 5px 0 0;
          font-size: 18px;
          line-height: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .subtitle {
          display: none;
        }
        .icon-container {
          display: none;
        }
      }
    }
    .right-column {
      padding: 0;
      button {
        line-height: 21px;
        height: 30px;
        width: 30px;
        min-width: 0;
        padding: 0 10px;
      }
      :last-child {
        margin-right: 0 !important;
      }
    }
  }
}
